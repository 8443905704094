import React from "react";
import ServiceCard from "../Presentational/Cards/ServiceCard";
import { getServicecards } from "../../Utilities/helpers";
import TopBanner from "../Presentational/TopBanner";
import BottomBanner from "../Presentational/BottomBanner";

const Home = () => {
  const cards = getServicecards();

  return (
    <>
      <TopBanner currentPage="home" />
      <div className="flex flex-col items-center justify-center space-y-8 py-12">
        <div className="flex flex-wrap justify-center gap-12 max-w-[1600px]">
          {cards.map((card) => (
            <ServiceCard
              key={card.id}
              bgImg={card.bgImg}
              titleFront={card.titleFront}
              titleBack={card.titleBack}
              descriptionBack={card.descriptionBack}
            />
          ))}
        </div>
      </div>
      <BottomBanner currentPage="home" />
    </>
  );
};

export default Home;
