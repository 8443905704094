import React from "react";

const Title = (props) => {
  return (
    <div className={`${props.additionalClassNames} p-5 h1`}>
      <h1 className={`${props.additionalClassNamesH1}`}>{props.title}</h1>
    </div>
  );
};

export default Title;
