import React from "react";
import yogaIcon from "../../Assets/Images/yogaIcon.png";

const Quote = ({ text, author }) => {
  return (
    <blockquote className="pl-4 italic text-accent flex flex-col gap-6">
      <img
        src={yogaIcon}
        alt="Yoga Icon"
        className="w-32 h-auto place-self-center"
      />
      <p>"{text}"</p>
      <footer className="mt-2 text-center text-accent">— {author}</footer>
    </blockquote>
  );
};

export default Quote;
