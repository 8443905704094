import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ label, type = "navigate", onSubmit, className }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (type === "navigate") {
      navigate("/contact");
    } else if (type === "submit") {
      onSubmit && onSubmit();
    }
  };

  return (
    <button onClick={handleClick} className={`btn ${className}`}>
      {label}
    </button>
  );
};

export default Button;
