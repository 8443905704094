import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";

const ServiceCard = ({ bgImg, titleFront, titleBack, descriptionBack }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleMouseEnter = () => setIsFlipped(true);
  const handleMouseLeave = () => setIsFlipped(false);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div
        className="relative border-none rounded-sm shadow-xl bg-white h-96 lg:w-96 xl:w-96 md:w-80 sm:w-64 xs:w-64 overflow-hidden cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={bgImg}
          alt="Icon"
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="absolute bottom-4 inset-x-0 flex flex-col items-center text-white space-y-4">
          <h2 className="text-2xl font-bold text-shadow-xl">{titleFront}</h2>
        </div>
      </div>
      <div
        className="flex flex-col border-none rounded-lg shadow-xl bg-background p-8 back-card h-96 lg:w-96 xl:w-96 md:w-80 sm:w:64 xs:w-64 justify-center cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <h2 className="h2 font-medium text-shadow-sm text-primary mb-4 text-center">
          {titleBack}
        </h2>
        <p className="text-center text-pretty text-accent">{descriptionBack}</p>
      </div>
    </ReactCardFlip>
  );
};

export default ServiceCard;
