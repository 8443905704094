import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../Assets/Logo/UpdateYogaLogo.png";
import { FiMenu, FiX } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { FaFacebookF } from "react-icons/fa";

const linksLeft = [
  { to: "/", title: "Home" },
  { to: "/about", title: "About" },
  { to: "/services", title: "Services" },
];

const linksRight = [
  { to: "/events", title: "Schedule/Events" },
  { to: "/contact", title: "Contact" },
];

const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = () => {
    setToggleMenu((prev) => !prev);
  };

  const handleLinkClick = () => {
    setToggleMenu(false);
  };

  return (
    <>
      <div className="bg-secondary px-4 py-3 font-bold flex justify-between">
        <div className="flex justify-between items-center">
          <p className="text-white flex justify-between">
            <div className="flex items-center">
              <HiOutlineMail className="text-primary text-xl" />
            </div>
            <div className="px-1 flex items-center text-primary">
              <p>
                <a href="mailto:contactheatherrae@13littleripples.com">
                  CONTACT US
                </a>
              </p>
            </div>
          </p>
        </div>
        <div className="flex text-primary justify-between items-center">
          <a
            href="https://www.facebook.com/people/13-Little-Ripples-With-Heather-Rae/100089598289801/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF className="text-xl" />
          </a>
        </div>
      </div>
      <nav className="bg-background text-teal-700 px-4 py-3 font-bold">
        <div className="flex justify-between items-center h-16">
          <div className="hidden md:flex space-x-6">
            {linksLeft.map((link) => (
              <NavLink
                key={link.title}
                to={link.to}
                className={({ isActive }) =>
                  `${
                    isActive ? "underline" : ""
                  } xl:text-xl font-medium transition-colors duration-300 hover:text-accent`
                }
                onClick={handleLinkClick}
              >
                {link.title.toUpperCase()}
              </NavLink>
            ))}
          </div>
          <div className="flex justify-center w-full lg:w-auto">
            <img src={logo} alt="Logo" className="w-32 h-auto" />
          </div>
          <div className="hidden md:flex space-x-6">
            {linksRight.map((link) => (
              <NavLink
                key={link.title}
                to={link.to}
                className={({ isActive }) =>
                  `${
                    isActive ? "underline" : ""
                  } xl:text-xl font-medium transition-colors duration-300 hover:text-accent`
                }
                onClick={handleLinkClick}
              >
                {link.title.toUpperCase()}
              </NavLink>
            ))}
          </div>
          <div className="md:hidden">
            <button onClick={handleToggleMenu}>
              {toggleMenu ? (
                <FiX className="text-2xl" />
              ) : (
                <FiMenu className="text-2xl" />
              )}
            </button>
          </div>
        </div>
        {toggleMenu && (
          <div className="md:hidden flex flex-col items-center space-y-6 py-4 pr-5 bg-background z-50">
            {[...linksLeft, ...linksRight].map((link) => (
              <NavLink
                key={link.title}
                to={link.to}
                className={({ isActive }) =>
                  `${
                    isActive ? "underline" : ""
                  } xl:text-xl font-medium transition-colors duration-300 hover:text-accent`
                }
                onClick={handleLinkClick}
              >
                {link.title.toUpperCase()}
              </NavLink>
            ))}
          </div>
        )}
      </nav>
    </>
  );
};

export default NavBar;
