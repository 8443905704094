import Title from "../Presentational/Title";
import BottomBanner from "../Presentational/BottomBanner";
import Calendar from "../Presentational/Calendar";
import TopBanner from "../Presentational/TopBanner";

const Events = () => {
  return (
    <>
      <TopBanner currentPage="events" />
      <div className="pt-4">
        <Title title="UPCOMING CLASSES" additionalClassNamesH1="text-center" />
      </div>
      <div className="flex flex-col justify-center px-10 py-4">
        <div className="flex justify-center">
          <p className="text-center text-primary text-2xl">
            Stay connected and plan your path to balance with our class
            calendar! Explore all upcoming yoga sessions, workshops, and special
            events designed to support your journey toward well-being. Whether
            you're seeking relaxation, spiritual growth, or community
            connection, there's a class for everyone. Reserve your spot today
            and take the next step toward alignment and peace.
          </p>
        </div>
        <div className="flex justify-center py-8">
          <Calendar />
        </div>
      </div>
      <div className="pt-4">
        <Title title="UPCOMING EVENTS" additionalClassNamesH1="text-center" />
      </div>
      <div className="p-8 flex flex-cols justify-center">
       <Title title="Events Coming Soon ..." additionalClassNamesH1="text-primary" />
      </div>
      <BottomBanner currentPage="events" />
    </>
  );
};

export default Events;
