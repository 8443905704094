import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ImageComponent from "../Presentational/ImageComponent";
import contactImage from "../../Assets/Images/contactImage.jpg";
import BottomBanner from "../Presentational/BottomBanner";
import TopBanner from "../Presentational/TopBanner";
import Button from "../Presentational/Button";

const Contact = () => {
  const form = useRef();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
   

    emailjs
      .sendForm("service_utwvtsj", "template_hcm411h", form.current, {
        publicKey: "z6PYtEF8F9oJGItEQ",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
          setFirstname("");
          setLastname("");
          setEmail("");
          setMessage("");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <TopBanner currentPage="contact" />
      <div className="flex flex-col items-center justify-center space-y-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full max-w-4xl place-items-center">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-4xl font-bold text-center mb-3 md:mt-10 text-primary">
              Breath In, Reach Out{" "}
            </h1>
            <p className="mb-8 px-4 text-center">
              Let's Find Your Balance, Leave Me A Message:
            </p>
            <form onSubmit={sendEmail} ref={form}>
              <label htmlFor="firstName" className="mr-5">
                First Name:
              </label>
              <input
                type="text"
                className="mb-5 text-primary input"
                name="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="Your First Name.."
              />
              <br />
              <label htmlFor="lastName" className="mr-5">
                Last Name:
              </label>
              <input
                type="text"
                className="mb-5 text-primary input"
                name="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                placeholder="Your Last Name.."
              />
              <br />
              <label htmlFor="email" className="mr-14">
                Email:
              </label>
              <input
                type="email"
                className="mb-5 text-primary input"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email.."
              />
              <br />
              <label htmlFor="message" className="mr-8">
                Message:
              </label>
              <textarea
                name="message"
                className="text-primary input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Leave Me A Message.."
              ></textarea>
              <br />
              <div>
                <Button
                  label="Submit"
                  type="submit"
                />
              </div>
            </form>
          </div>

          <div className="justify-center px-10 h-full xl:w-full lg:w-full md:w-2/3 sm:w-3/4 xs:w-full">
            <ImageComponent
              src={contactImage}
              alt="contactImage"
              className="mt-10 border-primary object-cover w-full"
            />
          </div>
        </div>
      </div>
      <BottomBanner currentPage="contact" />
    </>
  );
};

export default Contact;
