import React from "react";
import Quote from "./Quote";
import Button from "./Button";

const Banner = ({
  backgroundImage,
  title,
  subtitle,
  showButton = false,
  buttonLabel = "Start Your Journey",
  showQuote = false,
  quoteText,
  quoteAuthor,
}) => {
  return (
    <>
      <div
        className="relative h-[70vh] w-full bg-cover bg-center flex items-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-center text-white md:px-10">
          <h1 className="text-4xl font-bold text-shadow-md ">{title}</h1>
          {subtitle && (
            <p className="mt-2 lg:text-2xl py-2 text-shadow-md">{subtitle}</p>
          )}
          {showButton && <Button label={buttonLabel} />}
        </div>
      </div>
      {showQuote && (
        <div className="bg-primary p-6 text-center mx-auto">
          <Quote text={quoteText} author={quoteAuthor} />
        </div>
      )}
    </>
  );
};

export default Banner;
