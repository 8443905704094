import React from "react";
import { NavLink } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";

const navLinks = [
  { to: "/", label: "HOME" },
  { to: "/about", label: "ABOUT" },
  { to: "/services", label: "SERVICES" },
  { to: "/events", label: "SCHEDULE/EVENTS" },
  { to: "/contact", label: "CONTACT" },
];

const Footer = () => {
  const renderNavLinks = () =>
    navLinks.map(({ to, label }) => (
      <NavLink
        key={to}
        to={to}
        className={({ isActive }) => (isActive ? "underline" : "")}
      >
        {label}
      </NavLink>
    ));

  return (
    <footer className="bg-primary text-white font-medium p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <HiOutlineMail className="text-xl" />
          <p className="text-lg">
            <a href="mailto:contactheatherrae@13littleripples.com">
              CONTACT US
            </a>
          </p>
        </div>
        <div className="hidden md:flex space-x-6">{renderNavLinks()}</div>
      </div>
    </footer>
  );
};

export default Footer;
