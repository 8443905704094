const Calendar = () => (
  <>
    <iframe
      src="https://calendar.google.com/calendar/embed?src=13littleripplesevents%40gmail.com&ctz=America%2FPhoenix"
      width="100%"
      height="630"
      title="Events Calendar"
      className="xl:w-3/4 w-full"
    ></iframe>
  </>
);
export default Calendar;
