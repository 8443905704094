import React from "react";
import { getBottomBanners } from "../../Utilities/helpers";
import Banner from "./Banner";

const BottomBanner = ({ currentPage }) => {
  const banner = getBottomBanners().find((b) => b.page === currentPage);
  if (!banner) return null;

  return (
    <Banner
      backgroundImage={banner.backgroundImage}
      title={banner.title}
      subtitle={banner.subtitle}
      showButton={banner.showButton}
      buttonLabel={banner.buttonLabel}
    />
  );
};

export default BottomBanner;
