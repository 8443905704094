import React from "react";
import PropTypes from "prop-types";

const ImageComponent = ({ src, alt, className }) => {
  return (
    <div>
      <img
        src={src}
        alt={alt}
        className={`w-96 h-[650px] border-4 border-primary rounded-lg object-cover ${className}`}
      />
    </div>
  );
};

ImageComponent.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

ImageComponent.defaultProps = {
  alt: "Image",
  className: "",
};

export default ImageComponent;
