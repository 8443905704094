import React from "react";
import { getTopBanners } from "../../Utilities/helpers";
import Banner from "./Banner";

const TopBanner = ({ currentPage }) => {
  const banner = getTopBanners().find((b) => b.page === currentPage);
  if (!banner) return null;

  return (
    <Banner
      backgroundImage={banner.backgroundImage}
      title={banner.title}
      subtitle={banner.subtitle}
      showButton={banner.showButton}
      buttonLabel={banner.buttonLabel}
      showQuote={banner.showQuote}
      quoteText={banner.quoteText}
      quoteAuthor={banner.quoteAuthor}
    />
  );
};

export default TopBanner;
