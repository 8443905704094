import ImageComponent from "../Presentational/ImageComponent";
import aboutImage from "../../Assets/Images/aboutImage.jpg";
import Title from "../Presentational/Title";
import BottomBanner from "../Presentational/BottomBanner";
import TopBanner from "../Presentational/TopBanner";

const About = () => {
  return (
    <>
      <TopBanner currentPage="about" />
      <div className="text-center">
        <Title title="Who I Am..." />
      </div>
      <div className="flex flex-col justify-around lg:flex-row p-6">
        <div className="flex justify-center">
          <ImageComponent
            src={aboutImage}
            alt="aboutImage"
            className="border-primary w-max"
          />
        </div>
        <div className="flex flex-col justify-around lg:w-1/2 p-5 text-primary">
          <p className="">
            Welcome! I'm Heather Davis, a passionate and dedicated yoga
            instructor specializing in Hatha Yoga, Yoga Nidra, Spiritual
            Coaching, Shamanic Reiki, and Sacred Circles. With years of
            experience, my journey through the healing arts has been a deep and
            transformative process. My goal is to help others find balance,
            peace, and inner strength by weaving together ancient wisdom and
            modern practices.
            <br />
            Whether you're seeking to improve your physical well-being, explore
            your inner world through meditation, or engage in energy healing, I
            offer a holistic approach to wellness. Through Hatha Yoga, we focus
            on mindful movement and breath, cultivating strength, flexibility,
            and clarity. Yoga Nidra provides the space for deep relaxation and
            healing, while Spiritual Coaching and Shamanic Reiki bring energetic
            alignment and personal empowerment.
            <br />
            I also lead Sacred Circles, a space for community, connection, and
            spiritual growth, where we can gather, share, and support one
            another in our unique journeys.
            <br />
            Let's explore the path of transformation together—body, mind, and
            spirit.
          </p>
          <br />
        </div>
      </div>
      <BottomBanner currentPage="about" />
    </>
  );
};

export default About;
