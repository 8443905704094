import Yoga from "../Assets/Images/HashaYoga.jpg";
import YogaNidra from "../Assets/Images/YogaNidra.jpg";
import SpiritualCoaching from "../Assets/Images/SpiritualCoaching.jpg";
import ShamanicReiki from "../Assets/Images/ShamanicReiki.jpg";
import SacredCircles from "../Assets/Images/SacredCircles.webp";
import homeTopBanner from "../Assets/Images/homeBanner.webp";
import AboutBannerImg from "../Assets/Images/aboutBanner.webp";
import ServicesBannerImg from "../Assets/Images/serviceBanner.jpg";
import eventsBanner from "../Assets/Images/eventBanner.jpg";
import contactBanner from "../Assets/Images/contactBanner.jpg";
import HomeBottomBanner from "../Assets/Images/HomeBottomBanner.jpg";
import AboutBottomBanner from "../Assets/Images/AboutBottomBanner.jpg";
import ServicesBottomBanner from "../Assets/Images/ServicesBottomBanner.jpg";
import EventsBottomBanner from "../Assets/Images/EventsBottomBanner.webp";
import ContactBottomBanner from "../Assets/Images/ContactBottomBanner.jpg";

export const getBottomBanners = () => [
  {
    id: 1,
    page: "home",
    backgroundImage: HomeBottomBanner,
    title: "13 LITTLE RIPPLES",
    subtitle:
      "A safe space for guided authentic spiritual connections, transitions and balance",
    showButton: true,
    buttonLabel: "Start Your Journey",
  },
  {
    id: 2,
    page: "about",
    backgroundImage: AboutBottomBanner,
    title: "MY JOURNEY",
    subtitle: "A journey of a thousand miles begins with a single step.",
    showButton: true,
    buttonLabel: "Start Your Journey",
  },
  {
    id: 3,
    page: "services",
    backgroundImage: ServicesBottomBanner,
    title: "OUR MISSION",
    subtitle:
      "Our mission is to provide a safe space for guided authentic spiritual connections, transitions and balance.",
    showButton: true,
    buttonLabel: "Start Your Journey",
  },
  {
    id: 4,
    page: "events",
    backgroundImage: EventsBottomBanner,
    title: "JOIN US",
    subtitle: "A journey of a thousand miles begins with a single step.",
    showButton: true,
    buttonLabel: "Start Your Journey",
  },
  {
    id: 5,
    page: "contact",
    backgroundImage: ContactBottomBanner,
    title: "GET CONNECTED",
    subtitle: "The journey of thousand miles begins with a single step",
    showButton: false,
  },
];

export const getTopBanners = () => [
  {
    id: 1,
    page: "home",
    backgroundImage: homeTopBanner,
    title: "13 LITTLE RIPPLES",
    subtitle:
      "A safe space for guided authentic spiritual connections, transitions and balance",
    showButton: true,
    buttonLabel: "Start Your Journey",
    showQuote: true,
    quoteText:
      "Yoga is not a workout, it is a work-in. And this is the point of spiritual practice...",
    quoteAuthor: "Rolf Gates",
  },
  {
    id: 2,
    page: "about",
    backgroundImage: AboutBannerImg,
    title: "ABOUT ME",
    subtitle:
      "Dedicated to guiding others on their journey to self-discovery, wellness, and inner peace.",
    showButton: true,
    buttonLabel: "Start Your Journey",
    showQuote: true,
    quoteText:
      "Yoga is the fountain of youth. You’re only as young as your spine is flexible.",
    quoteAuthor: "Bob Harper",
  },
  {
    id: 3,
    page: "services",
    backgroundImage: ServicesBannerImg,
    title: "OUR SERVICES",
    subtitle:
      "A variety of services to help you on your journey to a healthier, happier life.",
    showButton: true,
    buttonLabel: "Start Your Journey",
    showQuote: true,
    quoteText:
      "Yoga is the journey of the self, through the self, to the self. It is not an escape from reality but a mindful acceptance of it...",
    quoteAuthor: "Bhagavad Gita",
  },
  {
    id: 4,
    page: "events",
    backgroundImage: eventsBanner,
    title: "EVENTS AND CLASSES",
    subtitle:
      "Enriching events designed to deepen your practice and foster community connection.",
    showButton: true,
    buttonLabel: "Start Your Journey",
    showQuote: true,
    quoteText:
      "The rhythm of the body, the melody of the mind, and the harmony of the soul create the symphony of life.",
    quoteAuthor: "B.K.S. Iyengar",
  },
  {
    id: 5,
    page: "contact",
    backgroundImage: contactBanner,
    title: "GET CONNECTED",
    subtitle: "The journey of thousand miles begins with a single step",
    showButton: false,
    showQuote: true,
    quoteText:
      "The spiritual journey involves going beyond hope and fear, stepping into unknown territory...",
    quoteAuthor: "Pema Chodron",
  },
];

export const getServicecards = () => [
  {
    id: 1,
    bgImg: Yoga,
    titleFront: "Hatha Yoga",
    titleBack: "Benefits of Hatha Yoga",
    descriptionBack:
      "Yoga improves flexibility, strength, and balance, while promoting relaxation and reducing stress. It enhances mindfulness and overall well-being, aligning body, mind, and spirit.",
  },
  {
    id: 2,
    bgImg: YogaNidra,
    titleFront: "Yoga Nidra",
    titleBack: "Benefits of Yoga Nidra",
    descriptionBack:
      "Yoga Nidra is a deep relaxation practice that helps release tension and stress, improving sleep quality. It promotes emotional healing, inner peace, and mental clarity.",
  },
  {
    id: 3,
    bgImg: SpiritualCoaching,
    titleFront: "Spiritual Coaching",
    titleBack: "Benefits of Spiritual Coaching",
    descriptionBack:
      "Spiritual coaching guides you through life’s transitions by fostering self-awareness, personal growth, and clarity. It helps you reconnect with your purpose and align with your higher self.",
  },
  {
    id: 4,
    bgImg: ShamanicReiki,
    titleFront: "Shamanic Reiki",
    titleBack: "Benefits of Shamanic Reiki",
    descriptionBack:
      "Shamanic Reiki combines energy healing with shamanic practices to clear blockages, promote emotional healing, and restore balance to your body’s energy systems.",
  },
  {
    id: 5,
    bgImg: SacredCircles,
    titleFront: "Sacred Circles",
    titleBack: "Benefits of Sacred Circles",
    descriptionBack:
      "Sacred Circles provide a safe space for communal healing and reflection, fostering deep connections and spiritual growth through shared rituals and intentional conversation.",
  },
];
