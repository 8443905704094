import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Views/Container/Layout";
import Home from "./Views/Container/Home";
import About from "./Views/Container/About";
import Events from "./Views/Container/Events";
import Contact from "./Views/Container/Contact";
import Services from "./Views/Container/Services";
import "./global.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/events" element={<Events />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
